import React, { useEffect, useRef } from "react";
import SEO from "../../components/seo/seo";
import { PostPageContext, PageParams } from "../../../types";
import Footer from "../../components/footer/footer";
import Hero from "../../components/hero/hero";
import SvgImage from "../../components/svg-image/svg-image";
import CategoryIcon from "../../components/category-icon/category-icon";
import CarnetReportSVG from "../../images/carnet/reports.inline.svg";
import GooglePlaySVG from "../../images/svg/google_play.inline.svg";
import AppStoreSVG from "../../images/svg/app_store.inline.svg";
import BackgroundImage from "gatsby-background-image";
import CallFooter from "../../components/call-footer/call-footer";
import Section from "../../components/air-section/air-section";
// import Challenge from "./challenge";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Img from "gatsby-image";
// import Gallery from "./gallery";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
// import Reference from "./reference";
// import ReferencesSlider from "./references-slider";
import HeaderBig from "../../components/header-big/header-big";
import { GlobalState } from "../../components/layout/global-state";
import { Video } from "../../components/video/video";
import ScrollButton from "../../components/scroll-button/scroll-button";
const shortcodes = {
  Section,
  Footer,
  Img,
  Video,
  CarnetReportSVG,
  GooglePlaySVG,
  AppStoreSVG,
  SvgImage,
  BackgroundImage,
};
const RangeItemPage = (params: PageParams<PostPageContext>) => {
  const language = params.pageContext.language;
  const node = params.pageContext.node;
  const frontmatter = { ...node.childMdx.frontmatter };
  const heroColor =
    typeof frontmatter.hero_color === "string"
      ? frontmatter.hero_color
      : "inherit";
  const heroColorShadow =
    typeof frontmatter.hero_color_shadow === "string"
      ? frontmatter.hero_color_shadow
      : "inherit";
  let categories = node.childMdx.frontmatter.category;
  let gallery = node.childMdx.frontmatter.gallery
    ? [...node.childMdx.frontmatter.gallery]
    : [];
  gallery = gallery.map((galleryItem: any, index: number) => {
    return {
      fluid: galleryItem.childImageSharp.fluid,
      alt: node.childMdx.frontmatter.gallery_alt[index],
    };
  });
  if (heroColor !== "inherit") {
    GlobalState.set({ headerColor: heroColor });
  }
  if (!categories) {
    categories = [];
  }
  frontmatter.gallery = gallery;
  const scrollRef = useRef();
  useEffect(() => {
    return () => {
      GlobalState.set({ headerColor: null });

      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <>
      <SEO
        title={frontmatter.short_description + " " + frontmatter.title}
        hasHeroImage={true}
        description={frontmatter.description + " Aplinet ☎ +48 665 035 451"}
        lang={language}
      />
      <Section>
        Hello on website Ostrzeszów. template EN
      </Section>
    </>
  );
};

export default RangeItemPage;
